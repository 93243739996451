(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";
exports.__esModule = true;
exports.MakeRequest = void 0;
function getPercent(fraction, whole) {
    return (fraction / whole * 100).toFixed(2);
}
function MakeRequest(steamId, includeFreeGames, callback, errorCallback) {
    var requestUrl = "./php/getgamelist.php?steamid=".concat(steamId);
    if (includeFreeGames) {
        requestUrl += "&includefreegames=on";
    }
    // Make request
    fetch(requestUrl)
        .then(function (response) {
        if (response.ok) {
            return response.json();
        }
        else {
            errorCallback("HTTP error: ".concat(response.status, " ").concat(response.statusText));
            return;
        }
    })
        .then(function (jsonData) {
        if (jsonData !== undefined) {
            if (!jsonData.response) {
                errorCallback(jsonData.error);
                return;
            }
            if (Object.keys(jsonData.response).length == 0) {
                errorCallback("Empty response; make sure user's privacy settings allow looking at their games.");
                return;
            }
            var newJsonData = jsonData.response;
            ProcessResponse(newJsonData);
            callback(newJsonData);
        }
    });
}
exports.MakeRequest = MakeRequest;
function ProcessResponse(jsonData) {
    jsonData.games_60_plus = { games: [], num: 0, percent: "" };
    jsonData.games_60_minus = { games: [], num: 0, percent: "" };
    jsonData.games_zero = { games: [], num: 0, percent: "" };
    jsonData.playtime_forever = 0;
    jsonData.playtime_natural_language = "";
    // Sort based on (reverse) playtime first, then name
    jsonData.games.sort(function (gameA, gameB) {
        return gameA.playtime_forever == gameB.playtime_forever ?
            gameA.name.localeCompare(gameB.name) :
            gameB.playtime_forever - gameA.playtime_forever;
    });
    jsonData.games.forEach(function (game) {
        // Add playtime to total
        jsonData.playtime_forever += game.playtime_forever;
        // Categorize based on playtime
        if (game.playtime_forever == 0) {
            jsonData.games_zero.games.push(game);
        }
        else if (game.playtime_forever < 60) {
            jsonData.games_60_minus.games.push(game);
        }
        else {
            game.playtime_natural_language = getNaturalTime(game.playtime_forever);
            jsonData.games_60_plus.games.push(game);
        }
    });
    jsonData.playtime_natural_language = getNaturalTime(jsonData.playtime_forever);
    calculateStat(jsonData.games_60_plus, jsonData.game_count);
    calculateStat(jsonData.games_60_minus, jsonData.game_count);
    calculateStat(jsonData.games_zero, jsonData.game_count);
}
function calculateStat(gameCollection, gameCount) {
    gameCollection.num = gameCollection.games.length;
    gameCollection.percent = getPercent(gameCollection.games.length, gameCount);
}
// Time conversion to natural language (XXhYYm)
function getNaturalTime(minutes) {
    var hours = Math.floor(minutes / 60);
    var mins = minutes % 60;
    return "".concat(hours, "h").concat(mins, "m");
}

},{}],2:[function(require,module,exports){
"use strict";
exports.__esModule = true;
exports.setup = void 0;
var SteamHour = require("./SteamHour");
function setup() {
    var form = document.querySelector("form.steamid");
    var url = new URL(window.location.toString());
    var steamId = url.searchParams.get("steamid");
    var includeFreeGames = url.searchParams.get("includefreegames") == null ? false : true;
    if (steamId != null && steamId.length > 0) {
        makeRequest(steamId, includeFreeGames, displayResults, displayError);
    }
    updateForm(form, steamId, includeFreeGames);
    setupFormInteractivity(form);
}
exports.setup = setup;
function updateForm(form, steamId, includeFreeGames) {
    if (steamId != null || includeFreeGames) {
        var inputBox = form.querySelector("input[name=steamid]");
        inputBox.value = steamId;
        var freeGamesCheckbox = form.querySelector("input[name=includefreegames]");
        freeGamesCheckbox.checked = includeFreeGames;
    }
}
function setupFormInteractivity(form) {
    form.addEventListener("submit", function (event) {
        event.preventDefault();
        var formData = new FormData(event.target);
        var steamId = formData.get("steamid");
        var includeFreeGames = formData.get("includefreegames") === "on";
        makeRequest(steamId, includeFreeGames, displayResults, displayError);
        var url = new URL(window.location.toString());
        url.searchParams.set("steamid", steamId);
        url.searchParams["delete"]("includefreegames");
        if (includeFreeGames) {
            url.searchParams.append("includefreegames", "on");
        }
        window.history.pushState(null, null, url.toString());
    });
}
function makeRequest(steamId, includeFreeGames, callback, errorCallback) {
    showLoading(true);
    clearResults();
    SteamHour.MakeRequest(steamId, includeFreeGames, displayResults, displayError);
}
function showLoading(display) {
    if (display) {
        document.querySelector("section.loading").classList.add("active");
    }
    else {
        document.querySelector("section.loading").classList.remove("active");
    }
}
function displayResults(jsonData) {
    showLoading(false);
    updateOverallStats(jsonData);
    var column60Plus = makeColumn("60+ minutes", jsonData.games_60_plus);
    var column60Minus = makeColumn("1–59 minutes", jsonData.games_60_minus);
    var columnZero = makeColumn("0 minutes", jsonData.games_zero);
    document.querySelector("section.games").appendChild(column60Plus);
    document.querySelector("section.games").appendChild(column60Minus);
    document.querySelector("section.games").appendChild(columnZero);
    document.querySelector("section.summary").classList.add("active");
}
function displayError(error) {
    showLoading(false);
    document.querySelector("section.error p").textContent = error;
    document.querySelector("section.error").classList.add("active");
}
function clearResults() {
    document.querySelector("section.games").innerHTML = "";
    document.querySelector("section.summary").classList.remove("active");
    document.querySelector("section.error").classList.remove("active");
}
function updateOverallStats(jsonData) {
    document.querySelector("section.summary span.games_num")
        .textContent = jsonData.game_count.toString();
    document.querySelector("section.summary span.total_minutes")
        .textContent = "".concat(jsonData.playtime_forever, " (").concat(jsonData.playtime_natural_language, ")");
    updateSingleOverallStat("sixtyplus", jsonData.games_60_plus);
    updateSingleOverallStat("lessthansixty", jsonData.games_60_minus);
    updateSingleOverallStat("zerominutes", jsonData.games_zero);
}
function updateSingleOverallStat(baseSelector, gameCollection) {
    document.querySelector("section.summary span.".concat(baseSelector, ".num"))
        .textContent = gameCollection.num.toString();
    document.querySelector("section.summary span.".concat(baseSelector, ".percent"))
        .textContent = gameCollection.percent;
}
function makeColumn(headerText, gameCollection) {
    var columnTemplate = document.querySelector("template.column");
    var column = document.importNode(columnTemplate, true).content;
    column.querySelector("h2").textContent = headerText;
    column.querySelector("span.num").textContent = gameCollection.num.toString();
    column.querySelector("span.percent").textContent = gameCollection.percent;
    var gamelistContainer = column.querySelector("section.gamelist");
    // Create element for each game
    gameCollection.games.forEach(function (gameData) {
        var gameElement = makeGameElement(gameData);
        gamelistContainer.appendChild(gameElement);
    });
    return column;
}
function makeGameElement(game) {
    var gameElementTemplate = document.querySelector("template.game");
    var gameElement = document.importNode(gameElementTemplate, true).content;
    var iconImage = game.img_icon_url ?
        "https://media.steampowered.com/steamcommunity/public/images/apps/".concat(game.appid, "/").concat(game.img_icon_url, ".jpg")
        : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAAH0lEQVR42mNkoBAwjhowasCoAaMGjBowasCoAcPNAACOMAAhOO/A7wAAAABJRU5ErkJggg==";
    gameElement.querySelector("img.gameicon").setAttribute("src", iconImage);
    gameElement.querySelector("h3").textContent = game.name;
    var gametimeElement = gameElement.querySelector(".gametime");
    var gametimeHrsElement = gameElement.querySelector(".gametime_hrs");
    if (game.playtime_forever > 0) {
        gametimeElement.textContent = game.playtime_forever.toString() + " minutes";
    }
    else {
        gametimeElement.remove();
    }
    if (game.playtime_forever >= 60) {
        gametimeHrsElement.textContent = game.playtime_natural_language;
    }
    else {
        gametimeHrsElement.remove();
    }
    return gameElement;
}

},{"./SteamHour":1}],3:[function(require,module,exports){
"use strict";
exports.__esModule = true;
var SteamHourUI = require("./SteamHourUI");
SteamHourUI.setup();

},{"./SteamHourUI":2}]},{},[3]);
